<template>
    <div :id="`${type}-modal`" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialogLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title">{{ isIndispo ? 'Indisponibilité' : 'Rendez-vous' }}</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <IndispoForm v-if="isIndispo" ref="indispoForm" />
                    <RdvForm v-else ref="rdvForm" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Modal } from 'bootstrap'

import RdvForm from './RdvForm.vue'
import IndispoForm from './IndispoForm.vue'

export default {
    name: 'EventModal',
    props: ['type'],
    components: {
        RdvForm,
        IndispoForm,
    },
    data: () => ({
        modal: null,
    }),
    computed: {
        ...mapGetters(['isFaf', 'isCati']),
        isIndispo() {
            return this.type === 'indispo'
        },
    },
    mounted() {
        const modalElt = document.getElementById(`${this.type}-modal`)
        this.modal = new Modal(modalElt)
    },
    unmounted() {
        this.modal.hide()
    },
}
</script>

<style lang="scss" scoped>
.modal-header {
    background: #eee;
}
</style>

<template>
    <footer class="small text-muted text-center">REPONSE-RDV v{{ version }} - &copy;Kantar</footer>
</template>

<script>
export default {
    name: 'Footer',
    data: () => ({
        version: process.env.VUE_APP_VERSION,
    }),
}
</script>
<template>
    <h2 class="text-center">Page non trouvée</h2>
    <div class="'alert alert-danger text-center p-2" role="alert">La page que vous cherchez à atteindre n'existe pas.</div>
    <div class="text-center mt-3">
        <router-link to="/" class="card-link fs-n1">Retour</router-link>
    </div>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>

<template>
    <main role="main">
        <div class="container">
            <div class="row">
                <form class="row mt-2 g-3" @submit.prevent="selectEtab">
                    <div class="col-3">
                        <input v-if="(isAdmin && !adminIsImpersonating) || isCati" id="all-etabs" class="form-control w-auto" v-model="inputEtabId" placeholder="Saisir l’ID de l’établissement" />
                    </div>
                    <div class="col-7">
                        <button v-if="(isAdmin && !adminIsImpersonating) || isCati" type="submit" class="btn btn-primary mb-3" id="chargerButton">Charger</button>
                    </div>
                    <div v-if="isAdmin" class="col-2">
                        <select id="changeUserView" class="form-select w-auto" @change="onChangeUserView($event)">
                            <optgroup label="CATI">
                                <option id="cati" selected value="cati">Vue CATI</option>
                            </optgroup>
                            <optgroup label="FAF">
                                <option v-for="user in fafList" :key="user.id" :value="user.id"><span v-if="!user.actif">(DÉSACTIVÉ) </span>{{ user.name }}</option>
                            </optgroup>
                        </select>
                    </div>
                </form>
            </div>
        </div>
        <div v-if="isAdmin" id="affectationButton" class="d-flex justify-content-end">
            <button type="submit" @click="redirectAffectation" class="btn btn-info">Accéder aux affectations</button>
        </div>

        <div v-if="(isAdmin && !adminIsImpersonating) || isCati" class="etab text-center">
            <template v-if="etab">
                {{ etab.name }}<br /><span v-if="!hasAffectations" class="text-danger">Aucune affectation</span><span v-else>{{ formattedDate }}</span></template
            >
            <template v-else> Aucun établissement chargé </template>
        </div>

        <div v-else-if="isFaf" class="d-flex justify-content-end mt-3">
            <div class="form-check">
                <input class="form-check-input" type="radio" name="view" id="week-view" value="Days" v-model="selectedCalenderMode" checked />
                <label class="form-check-label" for="week-view"> Semaine</label>
            </div>

            <div class="form-check ms-2">
                <input class="form-check-input" type="radio" name="view" id="day-view" value="Day" v-model="selectedCalenderMode" />
                <label class="form-check-label" for="day-view"> Jour</label>
            </div>
        </div>

        <div id="calendar" class="row mt-3">
            <div class="col-sm-3 mb-4">
                <DayPilotNavigator id="nav" :config="navigatorConfig" ref="navigator" />
            </div>
            <div class="col-sm-9">
                <DayPilotCalendar id="dp" :config="calendarConfig" ref="calendar" />
            </div>
        </div>
    </main>

    <EventModal type="rdv" ref="rdvModal" />
    <EventModal type="indispo" ref="indispoModal" />
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { DayPilot, DayPilotCalendar, DayPilotNavigator } from '@daypilot/daypilot-lite-vue'

import EventModal from '@/components/EventModal.vue'

export default {
    name: 'RDV',
    data: function () {
        return {
            allEtabs: [],
            selectedCalenderMode: 'Days',
            inputEtabId: null,
            adminIsImpersonating: null,
            fafList: [],
            navigatorConfig: {
                selectMode: 'Day',
                locale: 'fr-fr',
                cellWidth: 40,
                cellHeight: 40,
                onTimeRangeSelected: (args) => {
                    this.selectedDate = this.$refs.navigator.control.selectionDay
                    this.calendarConfig.startDate = args.day
                },
            },
            calendarConfig: {
                viewType: 'Resources',
                heightSpec: 'BusinessHoursNoScroll',
                businessBeginsHour: 8,
                businessEndsHour: 22,
                timeFormat: 'Clock24Hours',
                locale: 'fr-fr',
                cellHeight: 28,
                headerHeight: 45,
                headerDateFormat: 'dddd d MMMM',
                allowEventOverlap: false, // KNOWN ISSUE: no effect ?
                timeRangeSelectedHandling: 'Enabled',
                eventDeleteHandling: 'Disabled',
                onTimeRangeSelected: async (args) => {
                    if (this.isCati || (this.isAdmin && !this.adminIsImpersonating)) {
                        this.setCurrentEvent(null)

                        // NB: DayPilot doesn't handle businessBeginsHour setting with Resources viewType - add it manually
                        const startDatetime = new Date(new Date(args.start.value).getTime() + this.calendarConfig.businessBeginsHour * 60 * 60 * 1000)
                        const rdvModal = this.$refs.rdvModal
                        rdvModal.$refs.rdvForm.fillForm('', this.etab.name, this.etab.id, 'rd', args.resource, startDatetime, null, null, this.adminIsImpersonating)
                        rdvModal.modal.show()
                    } else {
                        const startDatetime = new Date(args.start.value)
                        const endDatetime = new Date(args.end.value)
                        const indispoModal = this.$refs.indispoModal
                        indispoModal.$refs.indispoForm.fillForm(null, startDatetime, endDatetime, null, this.adminIsImpersonating)
                        indispoModal.modal.show()
                    }
                    this.$refs.calendar.control.clearSelection()
                },
                onEventClick: (args) => {
                    const calendarEvent = args.e.data
                    if (!calendarEvent.clickDisabled) {
                        // NB: disabled click doesn't work naturally ?
                        /// TODO: handle both RDV and indispo
                        if (this.isCati || (this.isAdmin && !this.adminIsImpersonating)) {
                            // NB: existing respRole means RDV event - TODO: use an eventType attribute ?
                            if (calendarEvent.respRole) {
                                this.setCurrentEvent({ id: calendarEvent.id, status: calendarEvent.status })

                                // NB: DayPilot doesn't handle businessBeginsHour setting with Resources viewType - add it manually
                                const startDatetime = new Date(new Date(calendarEvent.start.value).getTime() + this.calendarConfig.businessBeginsHour * 60 * 60 * 1000)
                                const rdvModal = this.$refs.rdvModal
                                rdvModal.$refs.rdvForm.fillForm(
                                    calendarEvent.id,
                                    calendarEvent.etabName,
                                    calendarEvent.etabId,
                                    calendarEvent.respRole,
                                    calendarEvent.resource,
                                    startDatetime,
                                    calendarEvent.text,
                                    calendarEvent.contact,
                                    this.adminIsImpersonating,
                                )
                                rdvModal.modal.show()
                            } else {
                                // indispo event
                                const startDatetime = new Date(new Date(calendarEvent.start.value).getTime() + this.calendarConfig.businessBeginsHour * 60 * 60 * 1000)
                                const endDatetime = new Date(new Date(calendarEvent.end.value).getTime() + this.calendarConfig.businessBeginsHour * 60 * 60 * 1000)
                                const indispoModal = this.$refs.indispoModal

                                indispoModal.$refs.indispoForm.fillForm(calendarEvent.id, startDatetime, endDatetime, calendarEvent.text, this.adminIsImpersonating)
                                indispoModal.modal.show()
                            }
                        } else {
                            // NB: existing respRole means RDV event - TODO: use an eventType attribute ?
                            if (calendarEvent.respRole) {
                                this.setCurrentEvent({ id: calendarEvent.id, status: calendarEvent.status })

                                let startDatetime = new Date(calendarEvent.start.value)
                                if (this.isAdmin && !this.adminIsImpersonating)
                                    startDatetime = new Date(new Date(calendarEvent.start.value).getTime() + this.calendarConfig.businessBeginsHour * 60 * 60 * 1000)

                                const rdvModal = this.$refs.rdvModal
                                rdvModal.$refs.rdvForm.fillForm(
                                    calendarEvent.id,
                                    calendarEvent.etabName,
                                    calendarEvent.etabId,
                                    calendarEvent.respRole,
                                    this.adminIsImpersonating || calendarEvent.resource,
                                    startDatetime,
                                    calendarEvent.text,
                                    calendarEvent.contact,
                                    this.adminIsImpersonating,
                                )
                                rdvModal.modal.show()
                            } else {
                                // indispo event
                                const startDatetime = new Date(calendarEvent.start.value)
                                const endDatetime = new Date(calendarEvent.end.value)
                                const indispoModal = this.$refs.indispoModal

                                indispoModal.$refs.indispoForm.fillForm(calendarEvent.id, startDatetime, endDatetime, calendarEvent.text, this.adminIsImpersonating)
                                indispoModal.modal.show()
                            }
                        }
                    }
                },
                onEventMoved: (args) => {
                    alert('Modification non disponible actuellement')
                    /// TODO: API call to update event
                },
                /* TODO for indispo ?
                onEventResized: (args) => {
                    console.log('Event resized', args.e)
                },
                */
            },
            selectedDate: null,
        }
    },
    components: {
        DayPilotCalendar,
        DayPilotNavigator,
        EventModal,
    },
    computed: {
        ...mapState(['etab', 'affectations']),
        ...mapGetters(['isFaf', 'isCati', 'isAdmin']),
        formattedDate() {
            return new DayPilot.Date(this.selectedDate).toString('dddd d MMMM', 'fr-fr')
        },
        hasAffectations() {
            return this.affectations.length > 0
        },
    },
    watch: {
        selectedCalenderMode: function (newMode) {
            //newMode == Days (mode semaine) ou day (un jour)
            this.$refs.calendar.config.viewType = newMode
            this.$refs.calendar.config.days = 5
            this.$refs.calendar.config.eventResizeHandling = 'Disabled'
            this.$refs.calendar.config.eventMoveHandling = 'Disabled'
            this.$refs.navigator.config.selectMode = newMode == 'WorkWeek' ? 'Week' : 'Day'
            this.loadUserEvents()
        },
    },
    methods: {
        ...mapActions(['getAllEtabs', 'setCurrentEtab', 'getAffectations', 'getEtabRdv', 'getUserRdv', 'getUserIndispo', 'getEtabIndispo', 'setCurrentEvent', 'getFafList']),
        /**
         * ADMIN
         */
        async onChangeUserView(event) {
            //@Cyril
            /**
             * récupération de tous les id rdv pour les supprimer
             */
            if (this.$refs.calendar.control.events.list)
                this.$refs.calendar.control.events.list.forEach((event) => {
                    const calendarEvent = this.$refs.calendar.control.events.find(event.id)
                    this.$refs.calendar.control.events.remove(calendarEvent)
                })
            this.$refs.navigator.control.update()

            if (event.target.value == 'cati') {
                this.adminIsImpersonating = null

                if (!this.inputEtabId) {
                    this.$refs.calendar.config.viewType = 'Resources'
                    this.$refs.calendar.config.eventResizeHandling = 'Disabled'
                    this.$refs.calendar.config.eventMoveHandling = 'Disabled'
                    this.$refs.navigator.config.selectMode = 'Day'

                    await this.loadEtabs()
                } else {
                    await this.selectEtab()
                }
            } else {
                this.adminIsImpersonating = event.target.value
                this.$refs.calendar.config.viewType = 'Days'
                this.$refs.calendar.config.days = 5
                this.$refs.calendar.config.eventResizeHandling = 'Disabled'
                this.$refs.calendar.config.eventMoveHandling = 'Disabled'
                await this.loadUserEvents(event.target.value)
            }
        },

        /**
         * CATI user
         */
        async selectEtab() {
            this.selectedDate = this.$refs.navigator.control.selectionDay
            const searchEtab = this.allEtabs.find((e) => e.id == this.inputEtabId)

            // remise de l'affichage en mode CATI
            if (this.isAdmin) {
                this.$refs.calendar.config.viewType = 'Resources'
                this.$refs.calendar.config.eventResizeHandling = 'Disabled'
                this.$refs.calendar.config.eventMoveHandling = 'Disabled'
                this.$refs.navigator.config.selectMode = 'Day'

                //remettre "Afficher la vue CATI" en selected
                const $select = document.querySelector('#changeUserView')
                const $option = $select.querySelector('#cati')
                $select.value = $option.value
                this.adminIsImpersonating = null
            }

            if (searchEtab) {
                this.$router.push(`/rdv/${searchEtab.id}`)
                ///document.getElementById('all-etabs').blur()
                this.setCurrentEtab(searchEtab)
                await this.loadResources()
                await this.loadEtabEvents()
            } else {
                /// TODO: do it better
                alert('Aucun établissement trouvé')
            }
        },
        async loadEtabs() {
            this.allEtabs = await this.getAllEtabs()
        },
        async loadResources() {
            const affectations = await this.getAffectations()
            this.$refs.calendar.control.update({ columns: affectations })
        },
        async loadEtabEvents() {
            const rdvEvents = await this.getEtabRdv()
            const indispoEvents = await this.getEtabIndispo()
            const events = [...rdvEvents, ...indispoEvents]

            //NB : le calendrier doit être mis à jour dans tous les cas (même si il n'y a pas de rdv)
            this.$refs.calendar.control.update({ events })
            this.$refs.navigator.control.update({ events })
        },
        /**
         * FAF user
         */
        async loadUserEvents(userId = null) {
            const rdvEvents = await this.getUserRdv(userId)
            const indispoEvents = await this.getUserIndispo(userId)
            const events = [...rdvEvents, ...indispoEvents]

            // if (events.length) {
            this.$refs.calendar.control.update({ events })
            this.$refs.navigator.control.update({ events })
            // }
        },

        async redirectAffectation() {
            window.location.href = '/affectations'
        },
    },
    async mounted() {
        if (this.isCati || this.isAdmin) {
            if (this.isAdmin) this.fafList = await this.getFafList()
            if (this.$route.params.etabId) {
                this.inputEtabId = this.$route.params.etabId
                await this.loadEtabs()
                await this.selectEtab()
            } else {
                this.loadEtabs()
                // if (this.etab)

                /* TODO ? get value from store
                if (this.etab) {
                    this.inputEtabId = this.etab.id
                    this.loadEtabEvents()
                }
                */
            }
        } else {
            // this.$refs.calendar.config.viewType = 'Day'
            // this.$refs.calendar.config.viewType = 'WorkWeek'
            this.$refs.calendar.config.viewType = 'Days'
            this.$refs.calendar.config.days = 5
            this.$refs.calendar.config.eventResizeHandling = 'Disabled'
            this.$refs.calendar.config.eventMoveHandling = 'Disabled'
            this.loadUserEvents()
        }
    },
}
</script>

<style lang="scss">
#all-etabs {
    min-width: 320px;
}

.etab {
    font-size: 1.2em;
}

.navigator_default_busy.navigator_default_cell {
    border-bottom: 4px solid #ee4f2ecc;
    box-sizing: border-box;
}

#calendar {
    .calendar_default_cell_inner {
        background: #fff;
    }

    table tbody tr:nth-child(1) td:nth-child(2) table tbody {
        tr:nth-child(1) .calendar_default_cell_inner,
        tr:nth-child(24) .calendar_default_cell_inner,
        tr:nth-child(25) .calendar_default_cell_inner,
        tr:nth-child(26) .calendar_default_cell_inner,
        tr:nth-child(27) .calendar_default_cell_inner,
        tr:nth-child(28) .calendar_default_cell_inner {
            background: #f3f3f3;
        }
    }

    .calendar_default_event_inner {
        overflow-y: auto;

        h5 {
            font-size: 1em;
        }

        p {
            margin-bottom: 0.5rem;
        }

        .event-status {
            font-size: 1.1em;
            font-variant: small-caps;
        }
    }
}

#chargerButton {
    margin-left: 1em;
}

#changeUserView {
    margin-left: -2.2em;
    margin-bottom: 1.8em;
}
</style>

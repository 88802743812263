<template>
    <div class="wrapper container d-flex flex-column">
        <Header />
        <main role="main" class="flex-grow-1">
            <router-view />
        </main>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
    components: {
        Header,
        Footer,
    },
}
</script>

<style lang="scss" scoped>
.wrapper {
    min-height: 100vh;
}
</style>

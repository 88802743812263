<template>
    <form @submit.prevent="saveIndispo">
        <div class="mb-3">
            <label class="form-label">Début de l'indisponibilité</label>
            <div class="row">
                <div class="col-auto">
                    <input type="date" id="rdv-date" v-model="selectedStartDate" class="form-control" required :disabled="isCati || (isAdmin && !adminIsImpersonating)" />
                </div>
                <div class="col-auto">
                    <select v-model="selectedStartHour" class="form-select w-auto" required :disabled="isCati || (isAdmin && !adminIsImpersonating)">
                        <option v-for="h in hours" :key="h">{{ h }}</option>
                        <option v-for="h in extHours" :key="h">{{ h }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label">Fin de l'indisponibilité</label>
            <div class="row">
                <div class="col-auto">
                    <input type="date" v-model="selectedEndDate" class="form-control" required :disabled="isCati || (isAdmin && !adminIsImpersonating)" />
                </div>
                <div class="col-auto">
                    <select v-model="selectedEndHour" class="form-select w-auto" required :disabled="isCati || (isAdmin && !adminIsImpersonating)">
                        <option v-for="h in hours" :key="h">{{ h }}</option>
                        <option v-for="h in extHours" :key="h">{{ h }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label class="form-label">Raison</label>
            <textarea v-model="comments" class="form-control" required :disabled="isCati || (isAdmin && !adminIsImpersonating)"></textarea>
        </div>

        <div :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1' + (!alert ? ' invisible' : '')" role="alert" v-html="alert ? alert.message : '&nbsp;'"></div>

        <div v-if="!isCati" class="mb-3">
            <button v-if="isFaf || (isAdmin && adminIsImpersonating)" type="submit" class="btn btn-primary me-3">Enregistrer</button>
            <button v-if="indispoId" type="button" class="btn btn-danger" @click="removeIndispo">Supprimer</button>
        </div>

        <!-- <button v-if="!isCati" type="submit" class="btn btn-primary mb-3">Enregistrer</button> -->
    </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import dateFormat from 'date-format' /// TODO: use moment.js or DayPilot.Date instead

import Tools from '../tools'

export default {
    name: 'IndispoForm',
    mixins: [Tools],
    data: () => ({
        indispoId: null,
        selectedStartDate: null,
        selectedStartHour: null,
        selectedEndDate: null,
        selectedEndHour: null,
        comments: '',
        alert: null,
        adminIsImpersonating: null,
    }),
    computed: {
        ...mapGetters(['isFaf', 'isCati', 'isAdmin']),
    },
    methods: {
        ...mapActions(['createIndispo', 'updateIndispo', 'deleteIndispo']),

        /**
         * Pre-select values in RDV form.
         */
        fillForm(indispoId, startDatetime, endDatetime, comments, adminIsFaf) {
            this.adminIsImpersonating = adminIsFaf
            this.indispoId = indispoId
            this.selectedStartDate = dateFormat('yyyy-MM-dd', startDatetime)
            this.selectedStartHour = startDatetime.getHours() < 9 && startDatetime.getMinutes() < 30 ? '08:30' : dateFormat('hh:mm', startDatetime)
            this.selectedEndDate = dateFormat('yyyy-MM-dd', endDatetime)
            this.selectedEndHour = dateFormat('hh:mm', endDatetime)
            this.comments = comments
            this.alert = null
        },

        /**
         * Save a new or existing unavailability.
         */
        async saveIndispo() {
            // send indispo data to API
            const data = {
                start: this.selectedStartDate + 'T' + this.selectedStartHour + ':00',
                end: this.selectedEndDate + 'T' + this.selectedEndHour + ':00',
                reason: this.comments,
                userId: this.adminIsImpersonating,
            }

            let res = null
            if (this.indispoId) {
                // existing indispo
                res = await this.updateIndispo({ indispoId: this.indispoId, ...data })
            } else {
                // new indispo
                res = await this.createIndispo(data)
            }
            if (res.class === 'success') {
                // add or update event in calendar UI
                const calendar = this.$parent.$parent.$refs.calendar
                const navigator = this.$parent.$parent.$refs.navigator

                const fixedStart = new Date(data.start)
                const fixedEnd = new Date(data.end) // 2h30 duration//brieuc

                const calendarEvent = {
                    id: res.id, // ID provided by API
                    start: dateFormat('yyyy-MM-ddThh:mm:00', fixedStart),
                    end: dateFormat('yyyy-MM-ddThh:mm:00', fixedEnd),
                    text: data.reason,
                    html: this.formatIndispoEvent(data.reason),
                    barColor: '#000000',
                    resizeDisabled: true,
                    moveDisabled: true, /// TODO: allow for faf
                }

                if (this.indispoId) {
                    calendar.control.events.update(calendarEvent)
                } else {
                    calendar.control.events.add(calendarEvent)
                }
                navigator.control.update()

                // close modal
                const indispoModal = this.$parent.$parent.$refs.indispoModal
                indispoModal.modal.hide()
            } else {
                this.alert = res
            }
        },
        /**
         * Remove an existing indispo (cati user).
         */
        async removeIndispo() {
            if (confirm("Voulez-vous vraiment supprimer l'indisponibilitée ?")) {
                const res = await this.deleteIndispo(this.indispoId)
                if (res.class === 'success') {
                    const calendar = this.$parent.$parent.$refs.calendar
                    const navigator = this.$parent.$parent.$refs.navigator

                    const calendarEvent = calendar.control.events.find(this.indispoId)
                    calendar.control.events.remove(calendarEvent)
                    navigator.control.update()

                    const indispoModal = this.$parent.$parent.$refs.indispoModal
                    indispoModal.modal.hide()
                } else {
                    this.alert = res
                }
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.form-label {
    font-weight: bold;
}
</style>

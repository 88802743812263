<template>
    <div class="d-flex justify-content-center">
        <div class="card mt-5">
            <div class="card-body">
                <h2 class="card-title mb-3">Authentification</h2>
                <form @submit.prevent="signIn">
                    <div class="mb-3">
                        <label for="username">Nom d'utilisateur</label>
                        <input type="text" id="username" v-model="login" class="form-control" placeholder="Votre identifiant" required />
                    </div>

                    <div class="mb-3">
                        <label for="password">Mot de passe </label>
                        <div class="input-group">
                            <input :type="showPassword ? 'text' : 'password'" id="password" v-model="password" class="form-control" placeholder="Votre mot de passe" required />
                            <span class="input-group-text" @click="togglePassword"><i :class="'bi bi-eye-' + (showPassword ? 'slash-' : '') + 'fill'"></i></span>
                        </div>
                    </div>

                    <div
                        :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1' + (!alert ? ' invisible' : '')"
                        role="alert"
                        v-html="alert ? alert.message : '&nbsp;'"
                    ></div>

                    <div class="mt-3 mb-2 text-center">
                        <button type="submit" class="btn btn-primary rounded-pill" :disabled="loading">Se connecter</button>
                    </div>

                    <div class="text-center">
                        <input type="checkbox" id="stay-connected" v-model="remember" class="form-check-input" checked />
                        <label class="form-check-label ps-1 fw-light small" for="stay-connected">Rester connecté</label>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'Login',
    data: () => ({
        login: '',
        password: '',
        remember: false,
        showPassword: false,
        loading: false,
        alert: null,
    }),
    computed: {
        ...mapGetters(['isAuth']),
    },
    methods: {
        ...mapActions(['logIn']),
        togglePassword() {
            this.showPassword = !this.showPassword
        },
        async signIn() {
            this.alert = null
            const ret = await this.logIn({
                login: this.login,
                password: this.password,
                remember: this.remember,
            })
            if (this.isAuth) {
                this.$router.push('/rdv')
            } else {
                this.alert = ret
            }
        },
    },
}
</script>

<style lang="scss" scoped>
h2 {
    font-size: 1.5rem;
}
</style>
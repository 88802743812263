import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue'
import RDV from '../views/RDV.vue'
import NotFound from '../views/NotFound.vue'
import Affectations from '../views/Affectations.vue'
import store from '../store'

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: {
            auth: false,
        },
    },
    {
        path: '/rdv/:etabId?/:token?',
        name: 'RDV',
        component: RDV,
        meta: {
            auth: true,
        },
    },
    {
        path: '/affectations',
        name: 'Affectations',
        component: Affectations,
        meta: {
            auth: true,
        },
    },

    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

// Handle redirections
router.beforeEach((to, from, next) => {
    store.dispatch('initAuth', to.params.token).then(() => {
        const isAuth = store.getters.isAuth
        const isAdmin = store.getters.isAdmin

        if (to.matched.some((route) => route.meta.auth) && !isAuth) {
            next({ name: 'Login' })
        } else if (to.matched.some((route) => route.meta.auth === false) && isAuth) {
            next({ name: 'RDV' })
        } else if (to.name == 'Affectations' && !isAdmin) {
            next({ name: 'RDV' })
        } else {
            next()
        }
    })
})

export default router

<template>
    <main role="main">
        <div id="app">
            <div class="container">
                <form class="row mt-2 g-3" @submit.prevent="selectEtab">
                    <div class="row">
                        <div class="col-3">
                            <input id="all-etabs" class="form-control w-auto" v-model="inputEtabId" placeholder="Saisir l’ID de l’établissement" />
                        </div>
                        <div class="col-6" id="chargerButton">
                            <button type="submit" class="btn btn-primary mb-3">Charger</button>
                        </div>
                        <div class="col-2">
                            <div>
                                <button type="submit" @click="redirectRdv" class="btn btn-info mb-3">Accéder aux rdvs</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            <br />

            <div class="panel panel-default">
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <th>Id de l'enquêteur FAF</th>
                            <th>Nom de l'enquêteur FAF :</th>
                        </thead>
                        <tbody>
                            <tr v-for="item in affectations">
                                <td>{{ item.id }}</td>
                                <td>{{ item.name }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
            <div class="row mt-2 g-3" v-if="affectations.length">
                <div class="panel-heading"><strong> Liste Enquêteur FAF</strong></div>
                <div class="col-auto">
                    <select id="changeUserView" class="form-select w-auto" @change="onChangeFaf($event)">
                        <option v-for="user in fafList" :key="user.id" :value="user.id">{{ user.id }} - {{ user.name }}</option>
                    </select>
                </div>
                <div class="col-auto">
                    <button type="button" class="btn btn-primary" @click="addAffectation">Ajouter l'affectation</button>
                </div>
                <div :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1'" role="alert" v-html="alert ? alert.message : '&nbsp;'"></div>
            </div>
        </div>
    </main>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'Affectation',
    data: function () {
        return {
            affectations: [],
            inputEtabId: null,
            allEtabs: [],
            fafList: [],
            fafId: null,
            alert: null,
        }
    },
    components: {},
    computed: {},
    watch: {},
    methods: {
        ...mapActions(['getAffectations', 'getAllEtabs', 'setCurrentEtab', 'getFafList', 'createAffectation']),
        async selectEtab() {
            const searchEtab = this.allEtabs.find((e) => e.id == this.inputEtabId)

            if (searchEtab) {
                this.setCurrentEtab(searchEtab)
                await this.loadResources()
            } else {
                alert('Aucun établissement trouvé')
            }
        },
        async loadResources() {
            this.affectations = await this.getAffectations()
        },

        async addAffectation() {
            this.alert = null
            const data = {
                user_id: this.fafId,
                etab_id: this.inputEtabId,
            }
            const res = await this.createAffectation(data)
            if (res.class != 'danger') {
                const result = this.fafList.find(({ id }) => id === res[0].user_id)
                this.affectations.push(result)
            } else {
                this.alert = res
            }
        },

        async onChangeFaf(event) {
            console.log('event.target : ', event.target.value)
            this.alert = null
            this.fafId = event.target.value
        },
        async redirectRdv() {
            window.location.href = '/rdv'
        },
    },
    async mounted() {
        this.allEtabs = await this.getAllEtabs()
        this.fafList = await this.getFafList()

        this.fafId = this.fafList[0].id
    },
}
</script>

<style lang="scss" scoped>
#chargerButton {
    margin-left: 1em;
}

.container {
    margin-top: 2em;
}
</style>

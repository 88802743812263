<template>
    <form @submit.prevent="saveRdv">
        <div class="mb-3">
            <label class="form-label">Etablissement</label>
            <p>- {{ etabName }} (n° d'étab : {{ etabId }})</p>
            <!-- <input class="form-control w-auto" :value="etabName + ' ' + etabId" disabled /> -->
        </div>

        <div class="mb-3">
            <label class="form-label">Rôle du répondant</label>
            <select v-if="isCati" v-model="selectedRespRole" class="form-select w-auto" required :disabled="validatedEvent">
                <option v-for="r in respRoles" :key="r.id" :value="r.id">{{ r.label }}</option>
            </select>
            <select v-if="isAdmin && !adminIsFaf" v-model="selectedRespRole" class="form-select w-auto" required :disabled="false">
                <option v-for="r in respRoles" :key="r.id" :value="r.id">{{ r.label }}</option>
            </select>
            <span v-if="isFaf || adminIsFaf" v-for="item in respRoles" :key="item.id">
                <p v-if="item.id == selectedRespRole">- {{ item.label }}</p>
            </span>
        </div>

        <div v-if="isCati || (isAdmin && !adminIsFaf)" class="mb-3">
            <label class="form-label">Enquêteur</label>
            <select v-model="selectedUser" class="form-select w-auto" required :disabled="validatedEvent && !isAdmin">
                <option v-for="user in affectations" :key="user.id" :value="user.id">{{ user.name }}</option>
            </select>
        </div>

        <div class="mb-3">
            <label class="form-label">Début du RDV</label>
            <div class="row">
                <div class="col-auto">
                    <input v-if="isCati" type="date" v-model="selectedDate" class="form-control" required :disabled="validatedEvent" />
                    <input v-if="isFaf || isAdmin" type="date" v-model="selectedDate" class="form-control" required :disabled="false" />
                    <!-- :disabled="(isFaf && !validatedEvent) || (validatedEvent && !adminIsFaf && !isFaf)" -->
                </div>
                <div class="col-auto">
                    <select v-if="isCati" v-model="selectedHour" class="form-select w-auto" required :disabled="isCati && validatedEvent">
                        <option v-for="h in hours" :key="h">{{ h }}</option>
                    </select>
                    <select v-if="isFaf || isAdmin" v-model="selectedHour" class="form-select w-auto" required :disabled="false">
                        <option v-for="h in hours" :key="h">{{ h }}</option>
                    </select>
                </div>
            </div>
            <div class="form-text">Un créneau de 2h30 sera bloqué à partir de l'heure de début.</div>
        </div>

        <div class="mb-3">
            <label class="form-label">Commentaires</label>
            <textarea v-model="comments" v-if="!isAdmin" class="form-control" placeholder="Précisions facultatives" :disabled="validatedEvent && !isFaf"></textarea>
            <textarea v-model="comments" v-if="isAdmin" class="form-control" placeholder="Précisions facultatives" :disabled="false"></textarea>
        </div>

        <div class="mb-3" v-if="contact && rdvId">
            <label class="form-label">Information du contact</label>

            <table class="table table-sm">
                <tbody>
                    <tr>
                        <th scope="row">Nom :</th>
                        <td>{{ contact.civilite == 1 ? 'M.' : 'Mme' }} {{ contact.prenom }} {{ contact.nom }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Adresse :</th>
                        <td>{{ contact.adresse }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Email :</th>
                        <td>
                            <a :href="`mailto:${contact.email}`">{{ contact.email }}</a>
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">Fonction :</th>
                        <td>{{ contact.fonction }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Tel. mobile :</th>
                        <td>{{ contact.mobile }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Tel. fixe :</th>
                        <td>{{ contact.tel_fixe }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Id du répondant :</th>
                        <td>{{ contact.id_repondant }}</td>
                    </tr>
                    <tr>
                        <th scope="row">Mode passation :</th>
                        <td v-if="isCati">{{ contact.mode_passation == 2 ? 'Visio' : contact.mode_passation == 1 ? 'Face-à-face' : '' }}</td>

                        <!-- <select v-model="contact.mode_passation" class="form-control input-sm" required :disabled="validatedEvent && isCati"> -->
                        <select v-if="isFaf || isAdmin" v-model="contact.mode_passation" class="form-control input-sm" required :disabled="false">
                            <option id="0" value="0"></option>
                            <option id="1" value="1">Face-à-face</option>
                            <option id="2" value="2">Visio</option>
                        </select>
                    </tr>
                </tbody>
            </table>
        </div>

        <div :class="'alert' + (alert ? ' alert-' + alert.class : '') + ' text-center p-1 mt-2 fs-n1' + (!alert ? ' invisible' : '')" role="alert" v-html="alert ? alert.message : '&nbsp;'"></div>

        <div class="mb-3">
            <button v-if="!isFaf && !validatedEvent && !rdvId" type="submit" class="btn btn-primary me-3">Enregistrer</button>
            <button v-if="isCati && !validatedEvent" type="submit" class="btn btn-primary me-3">Modifier</button>
            <button v-if="!isCati && rdvId" type="submit" class="btn btn-primary me-3">Modifier</button>
            <!-- <button v-if="rdvId && !isCati && validatedEvent" type="submit" class="btn btn-primary me-3">Modifier</button> -->
            <button v-if="!isCati && !validatedEvent && rdvId" type="button" class="btn btn-success me-3" @click="validateRdv">Valider le RDV</button>
            <button v-if="!isFaf && rdvId && !validatedEvent" type="button" class="btn btn-danger" @click="removeRdv">Supprimer</button>
        </div>
    </form>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import dateFormat from 'date-format' /// TODO: use moment.js or DayPilot.Date instead

import Tools from '../tools'

export default {
    name: 'RdvForm',
    mixins: [Tools],
    data: () => ({
        rdvId: null,
        selectedUser: null,
        etabName: null,
        etabId: null,
        selectedRespRole: null,
        selectedDate: null,
        selectedHour: null,
        comments: null,
        alert: null,
        contact: null,
        adminIsFaf: false,
    }),
    computed: {
        ...mapState(['etab', 'event', 'affectations']),
        ...mapGetters(['isFaf', 'isCati', 'isAdmin', 'waitingEvent', 'validatedEvent']),
    },
    methods: {
        ...mapActions(['createRdv', 'updateRdv', 'deleteRdv', 'updateRdvStatus']),

        /**
         * Pre-select values in RDV form.
         */
        fillForm(rdvId, etabName, etabId, respRole, userId, startDatetime, comments, contact = null, adminIsFaf) {
            this.adminIsFaf = adminIsFaf

            this.rdvId = rdvId
            this.etabName = etabName
            this.etabId = etabId
            this.selectedRespRole = respRole
            this.selectedUser = userId
            this.selectedDate = dateFormat('yyyy-MM-dd', startDatetime)
            this.selectedHour = startDatetime.getHours() < 9 && startDatetime.getMinutes() < 30 ? '08:30' : startDatetime.getHours() > 19 ? '19:30' : dateFormat('hh:mm', startDatetime) // NB: 19:30 max
            this.comments = comments

            if (contact) {
                this.contact = contact
            }

            this.alert = null
        },

        /**
         * Save a new or existing RDV (cati user).
         */
        async saveRdv(button) {
            // send RDV data to API
            const data = {
                userId: this.selectedUser,
                respRole: this.selectedRespRole,
                startDate: this.selectedDate + 'T' + this.selectedHour + ':00',
                comments: this.comments || '',
                etabId: this.etabId ? this.etabId : null,
                modecoll: this.rdvId && this.contact && this.contact.mode_passation ? this.contact.mode_passation : null,
            }

            let res = null
            if (this.rdvId) {
                // existing RDV
                res = await this.updateRdv({ rdvId: this.rdvId, data })
            } else {
                // new RDV
                res = await this.createRdv({ etabId: this.etab.id, ...data })
            }

            if (res.class === 'success') {
                const calendar = this.$parent.$parent.$refs.calendar
                const navigator = this.$parent.$parent.$refs.navigator
                let fixedStart = null

                // NB: DayPilot doesn't handle its businessBeginsHour config automatically - remove it manually
                if (this.isCati || (this.isAdmin && !this.adminIsFaf)) {
                    fixedStart = new Date(new Date(data.startDate).getTime() - calendar.config.businessBeginsHour * 60 * 60 * 1000)
                } else {
                    fixedStart = new Date(data.startDate)
                }
                const fixedEnd = new Date(fixedStart.getTime() + 2.5 * 60 * 60 * 1000) // 2h30 duration

                const calendarEvent = {
                    id: this.rdvId || res.id,
                    etabName: this.etabName,
                    etabId: this.etabId,
                    respRole: this.selectedRespRole,
                    resource: data.userId || res.userId,
                    start: dateFormat('yyyy-MM-ddThh:mm:00', fixedStart),
                    end: dateFormat('yyyy-MM-ddThh:mm:00', fixedEnd),
                    text: data.comments,
                    html: this.formatRdvEvent(this.etabName, data.respRole, data.comments, 'waiting'),
                    status: 'waiting',
                    barColor: this.allRdvStatus.find((s) => s.id === 'waiting').color,
                    resizeDisabled: true,
                    moveDisabled: true, /// TODO: allow for cati
                    contact: this.contact || res.contact,
                }

                // add or update event in calendar UI
                if (this.rdvId) {
                    calendar.control.events.update(calendarEvent)
                } else {
                    calendar.control.events.add(calendarEvent)
                }
                navigator.control.update()

                // close modal
                const rdvModal = this.$parent.$parent.$refs.rdvModal
                rdvModal.modal.hide()
            } else {
                this.alert = res
            }
        },

        /**
         * Remove an existing RDV (cati user).
         */
        async removeRdv() {
            if (confirm('Voulez-vous vraiment supprimer le rendez-vous ?')) {
                const res = await this.deleteRdv({ rdvId: this.rdvId, userId: this.selectedUser })
                if (res.class === 'success') {
                    const calendar = this.$parent.$parent.$refs.calendar
                    const navigator = this.$parent.$parent.$refs.navigator

                    const calendarEvent = calendar.control.events.find(this.rdvId)
                    calendar.control.events.remove(calendarEvent)
                    navigator.control.update()

                    const rdvModal = this.$parent.$parent.$refs.rdvModal
                    rdvModal.modal.hide()
                } else {
                    this.alert = res
                }
            }
        },

        /**
         * Set validated status to a RDV (faf user).
         */
        async validateRdv() {
            const res = await this.updateRdvStatus({ rdvId: this.rdvId, status: 'validated' })
            if (res.class === 'success') {
                const calendar = this.$parent.$parent.$refs.calendar
                const calendarEvent = calendar.control.events.find(this.event.id)
                calendarEvent.data.html = this.formatRdvEvent(this.etabName, this.selectedRespRole, this.comments, 'validated')
                calendarEvent.data.status = 'validated'
                calendarEvent.data.barColor = this.allRdvStatus.find((s) => s.id === 'validated').color
                calendar.control.events.update(calendarEvent)

                const rdvModal = this.$parent.$parent.$refs.rdvModal
                rdvModal.modal.hide()
            }
        },

        // /**
        //  * Set refused status to a RDV (faf user).
        //  */
        // async refuseRdv() {
        //     const res = await this.updateRdvStatus({ rdvId: this.rdvId, status: 'refused' })
        //     if (res.class === 'success') {
        //         const calendar = this.$parent.$parent.$refs.calendar
        //         const calendarEvent = calendar.control.events.find(this.event.id)
        //         calendarEvent.data.html = this.formatRdvEvent(this.etabName, this.selectedRespRole, this.comments, 'refused')
        //         calendarEvent.data.status = 'refused'
        //         calendarEvent.data.barColor = this.allRdvStatus.find((s) => s.id === 'refused').color
        //         calendar.control.events.update(calendarEvent)

        //         const rdvModal = this.$parent.$parent.$refs.rdvModal
        //         rdvModal.modal.hide()
        //     }
        // },
    },
}
</script>

<style lang="scss" scoped>
.form-label {
    font-weight: 700;
    font-size: 1.2em;
}

.table th {
    font-weight: 600;
}
</style>

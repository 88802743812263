import { HOURS, EXT_HOURS, RESP_ROLES, RDV_STATUS } from './global'

export default {
    data: () => ({
        hours: HOURS,
        extHours: EXT_HOURS,
        respRoles: RESP_ROLES,
        allRdvStatus: RDV_STATUS,
    }),
    methods: {
        formatRdvEvent: function (etabName, clientRole, comments, status) {
            return `<div class="event-status mb-2" style="color:${RDV_STATUS.find((s) => s.id === status).color}">${RDV_STATUS.find((s) => s.id === status).label}</div><h5>${etabName}</h5><p>${
                RESP_ROLES.find((r) => r.id === clientRole).label
            }</p><p><em>${comments.replaceAll('\n', '<br>') || ''}</em></p>`
        },
        formatIndispoEvent: function (reason) {
            return `<div class="event-status mb-2" style="color:#000000">Indisponible</div><p><em>${reason.replaceAll('\n', '<br>')}</em></p>`
        },
    },
}

<template>
    <header role="banner" class="row">
        <div class="col"><img src="../assets/logo_dares.png" /></div>
        <h1 class="col-6 d-flex justify-content-center align-items-center">REPONSE - Gestion des rendez-vous</h1>
        <div class="col d-flex justify-content-end align-items-center">
            <div v-if="isAuth">
                {{ user.name }} -
                <a href="/" @click="signOut">Se déconnecter</a>
            </div>
        </div>
    </header>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    name: 'Header',
    computed: {
        ...mapState(['user']),
        ...mapGetters(['isAuth']),
    },
    methods: {
        ...mapActions(['logOut']),
        signOut() {
            this.$router.push('/')
            this.logOut()
        },
    },
}
</script>

<style lang="scss" scoped>
header {
    border-bottom: 1px solid #ddd;

    h1 {
        font-size: 2rem;
    }
}
</style>

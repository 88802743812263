export const HOURS = [
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
]

export const EXT_HOURS = ['20:00', '20:30', '21:00', '21:30', '22:00']

export const RESP_ROLES = [
    { id: 'rd', label: 'Représentant de la Direction' },
    { id: 'rp', label: 'Représentant du Personnel' },
]

export const RDV_STATUS = [
    { id: 'waiting', color: '#ffae00', label: 'En attente de validation' },
    { id: 'validated', color: '#6aa84f', label: 'Validé' },
    ///{ id: 'refused', color: '#cc4125', label: 'Refusé' },
]

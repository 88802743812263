import { createStore } from 'vuex'
import axios from 'axios'

import { RDV_STATUS } from '../global'
import Tools from '../tools'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

function lastSunday(year, month) {
    var date = new Date(year, month, 1, 12)
    let weekday = date.getDay()
    let dayDiff = weekday === 0 ? 7 : weekday
    let lastSunday = date.setDate(date.getDate() - dayDiff)
    return date.getDate()
}

export default new createStore({
    state: () => ({
        user: null,
        etab: null,
        affectations: [],
        event: null,
    }),
    getters: {
        isAuth: (state) => state.user !== null,
        isFaf: (state) => state.user && state.user.role === 'faf',
        isCati: (state) => state.user && state.user.role === 'cati',
        isAdmin: (state) => state.user && state.user.role === 'admin',
        waitingEvent: (state) => state.event && state.event.status === 'waiting',
        validatedEvent: (state) => state.event && state.event.status === 'validated',
    },
    mutations: {
        setUser: (state, payload) => {
            state.user = payload
        },
        setEtab: (state, payload) => {
            state.etab = payload
        },
        setAffectations: (state, payload) => {
            state.affectations = payload
        },
        setEvent: (state, payload) => {
            state.event = payload
        },
    },
    actions: {
        //////////
        // User //
        //////////

        /**
         * Log user in checking credentials through API and storing returned token.
         */
        async logIn({ dispatch }, payload) {
            try {
                const res = await axios.post('/login', payload)
                if (res.data) {
                    // store token
                    if (payload.remember) {
                        localStorage.setItem('token', res.data)
                    } else {
                        sessionStorage.setItem('token', res.data)
                    }
                    axios.defaults.headers.common.Authorization = 'Bearer ' + res.data
                    // get user profile
                    await dispatch('getUser')
                }
            } catch (err) {
                return err.response.data
            }
        },

        /**
         * Log user out clearing stored token and state.
         */
        logOut({ commit }) {
            delete axios.defaults.headers.common.Authorization
            localStorage.removeItem('token')
            sessionStorage.removeItem('token')
            commit('setUser', null)
        },

        /**
         * Load user profile automatically from existing stored token.
         */
        async initAuth({ dispatch }, token) {
            if (token) {
                axios.defaults.headers.common.Authorization = 'Bearer ' + token
                await dispatch('getUser')
            } else if (localStorage.getItem('token')) {
                axios.defaults.headers.common.Authorization = 'Bearer ' + localStorage.getItem('token')
                await dispatch('getUser')
            } else if (sessionStorage.getItem('token')) {
                axios.defaults.headers.common.Authorization = 'Bearer ' + sessionStorage.getItem('token')
                await dispatch('getUser')
            }
        },

        /**
         * Get user through API and keep it into state.
         */
        async getUser({ commit }) {
            const res = await axios.get('/user')
            if (res.status === 200) {
                commit('setUser', res.data)
            }
        },

        async getFafList({ commit }) {
            try {
                const res = await axios.get('/user/faf')
                return res.data
            } catch (error) {
                return error.response
            }
        },

        /////////////////
        // Affectation //
        /////////////////
        async createAffectation(context, data) {
            try {
                const res = await axios.post(`/affectation`, data)
                return res.data
            } catch (err) {
                return err.response.data
            }
        },

        ///////////
        // Etabs //
        ///////////

        async getAllEtabs() {
            try {
                const res = await axios.get('/etabs')
                return res.data
            } catch (err) {
                return err.response
            }
        },

        setCurrentEtab({ commit }, payload) {
            commit('setEtab', payload)
        },

        async getAffectations({ state, commit }) {
            try {
                const res = await axios.get(`/etab/${state.etab.id}/affectations`)
                console.log('res.data', res.data)

                commit('setAffectations', res.data)

                const formattedData = res.data.map((d) => ({ id: d.id, name: d.actif ? d.name : '(DÉSACTIVÉ) ' + d.name }))
                return formattedData
            } catch (err) {
                console.error(err)
            }
        },

        async getAffectationsDetails({ state, commit }) {
            try {
                const res = await axios.get(`/etab/${state.etab.id}/affectations/details`)
                commit('setAffectations', res.data)

                const formattedData = res.data.map((d) => ({ id: d.id, name: d.name }))
                return formattedData
            } catch (err) {
                console.error(err)
            }
        },

        async getEtabRdv({ state }) {
            try {
                const res = await axios.get(`/rdv/etab/${state.etab.id}`)

                if (!res.data) return []
                const formattedData = res.data.map((d) => {
                    const startDate = new Date(d.start_date)
                    const endDate = new Date(d.end_date)
                    // NB: DayPilot doesn't handle businessBeginsHour setting with Resources viewType - remove it manually
                    // NB: API also returns time at GMT - need to add 1 hour

                    const mars = lastSunday(startDate.getFullYear(), 3)
                    const septembre = lastSunday(startDate.getFullYear(), 10)

                    //getMonth[0] == janvier...
                    if (
                        (startDate.getMonth() == 2 && startDate.getDate() >= mars) ||
                        (startDate.getMonth() == 9 && startDate.getDate() < septembre) ||
                        (startDate.getMonth() >= 3 && startDate.getMonth() < 9)
                    ) {
                        //changement d'heure en mars ¯\_(ツ)_/¯
                        startDate.setTime(startDate.getTime() - 6 * 60 * 60 * 1000)
                        endDate.setTime(endDate.getTime() - 6 * 60 * 60 * 1000)
                    } else {
                        startDate.setTime(startDate.getTime() - 7 * 60 * 60 * 1000)
                        endDate.setTime(endDate.getTime() - 7 * 60 * 60 * 1000)
                    }

                    if (!d.contact || !d.contact.etab_id) d.contact = null

                    return {
                        id: d.id,
                        resource: d.user_id,
                        etabName: d.etab_name,
                        etabId: d.etab_id,
                        respRole: d.role_client,
                        start: startDate.toISOString(),
                        end: endDate.toISOString(),
                        text: d.comments,
                        html: Tools.methods.formatRdvEvent(d.etab_name, d.role_client, d.comments, d.status),
                        status: d.status,
                        barColor: RDV_STATUS.find((s) => s.id === d.status).color,
                        ///clickDisabled: d.status === 'validated',
                        resizeDisabled: true,
                        moveDisabled: true, /// TODO: allow for cati
                        contact: d.contact,
                    }
                })
                return formattedData
            } catch (err) {
                console.error(err)
            }
        },

        /////////
        // RDV //
        /////////

        //faf
        async getUserRdv({ state }, userId) {
            try {
                const res = await axios.get(`/rdv/user/${userId}`)

                if (!res.data) return []

                const formattedData = res.data.map((d) => {
                    const startDate = new Date(d.start_date)
                    const endDate = new Date(d.end_date)
                    // NB: API returns time at GMT - need to add 1 hour

                    const mars = lastSunday(startDate.getFullYear(), 3)
                    const septembre = lastSunday(startDate.getFullYear(), 10)
                    //getMonth[0] == janvier...
                    if (
                        (startDate.getMonth() == 2 && startDate.getDate() >= mars) ||
                        (startDate.getMonth() == 9 && startDate.getDate() < septembre) ||
                        (startDate.getMonth() >= 3 && startDate.getMonth() < 9)
                    ) {
                        //changement d'heure en mars ¯\_(ツ)_/¯
                        startDate.setTime(startDate.getTime() + 2 * 60 * 60 * 1000)
                        endDate.setTime(endDate.getTime() + 2 * 60 * 60 * 1000)
                    } else {
                        startDate.setTime(startDate.getTime() + 60 * 60 * 1000)
                        endDate.setTime(endDate.getTime() + 60 * 60 * 1000)
                    }

                    return {
                        id: d.id,
                        etabName: d.etab_name,
                        etabId: d.etab_id,
                        respRole: d.role_client,
                        start: startDate.toISOString(),
                        end: endDate.toISOString(),
                        text: d.comments,
                        html: Tools.methods.formatRdvEvent(d.etab_name, d.role_client, d.comments, d.status),
                        status: d.status,
                        barColor: RDV_STATUS.find((s) => s.id === d.status).color,
                        ///clickDisabled: d.status !== 'waiting',
                        resizeDisabled: true,
                        moveDisabled: true,
                        contact: d.contact,
                    }
                })
                return formattedData
            } catch (err) {
                console.error(err)
            }
        },

        async createRdv(context, data) {
            try {
                const res = await axios.post('/rdv', data)
                return res.data
            } catch (err) {
                return err.response.data
            }
        },

        async updateRdv(context, payload) {
            try {
                const res = await axios.post(`/rdv/${payload.rdvId}`, payload.data)
                return res.data
            } catch (err) {
                return err.response.data
            }
        },

        async updateRdvStatus(context, payload) {
            try {
                const res = await axios.post(`/rdv/status/${payload.rdvId}`, { status: payload.status })
                return res.data
            } catch (err) {
                return err.response.data
            }
        },

        async deleteRdv(context, payload) {
            try {
                const res = await axios.delete(`/rdv/${payload.rdvId}/${payload.userId}`)
                return res.data
            } catch (err) {
                return err.response.data
            }
        },

        setCurrentEvent({ commit }, calendarEvent) {
            commit('setEvent', calendarEvent)
        },

        /////////////
        // Indispo //
        /////////////

        async getUserIndispo({ state }, userId) {
            try {
                const res = await axios.get(`/indispo/user/${userId}`)
                const formattedData = res.data.map((d) => {
                    const startDate = new Date(d.start_date)
                    const endDate = new Date(d.end_date)
                    // NB: API returns time at GMT - need to add 1 hour

                    const mars = lastSunday(startDate.getFullYear(), 3)
                    const septembre = lastSunday(startDate.getFullYear(), 10)

                    if (
                        (startDate.getMonth() == 2 && startDate.getDate() >= mars) ||
                        (startDate.getMonth() == 9 && startDate.getDate() < septembre) ||
                        (startDate.getMonth() >= 3 && startDate.getMonth() < 9)
                    ) {
                        //changement d'heure en avril ¯\_(ツ)_/¯ ???!
                        startDate.setTime(startDate.getTime() + 2 * 60 * 60 * 1000)
                        endDate.setTime(endDate.getTime() + 2 * 60 * 60 * 1000)
                    } else {
                        startDate.setTime(startDate.getTime() + 60 * 60 * 1000)
                        endDate.setTime(endDate.getTime() + 60 * 60 * 1000)
                    }

                    return {
                        id: d.id,
                        start: startDate.toISOString(),
                        end: endDate.toISOString(),
                        barColor: '#000000',
                        text: d.reason,
                        html: Tools.methods.formatIndispoEvent(d.reason),
                        resizeDisabled: true, /// TODO: allow for faf
                        moveDisabled: true, /// TODO: allow for faf
                    }
                })
                return formattedData
            } catch (err) {
                console.error(err)
            }
        },

        async getEtabIndispo({ state }) {
            try {
                const res = await axios.get(`/indispo/etab/${state.etab.id}`)
                const formattedData = res.data.map((d) => {
                    const startDate = new Date(d.start_date)
                    const endDate = new Date(d.end_date)
                    // NB: DayPilot doesn't handle businessBeginsHour setting with Resources viewType - remove it manually
                    // NB: API also returns time at GMT - need to add 1 hour

                    const mars = lastSunday(startDate.getFullYear(), 3)
                    const septembre = lastSunday(startDate.getFullYear(), 10)

                    if (
                        (startDate.getMonth() == 2 && startDate.getDate() >= mars) ||
                        (startDate.getMonth() == 9 && startDate.getDate() < septembre) ||
                        (startDate.getMonth() >= 3 && startDate.getMonth() < 9)
                    ) {
                        //changement d'heure en avril ¯\_(ツ)_/¯ ???!
                        startDate.setTime(startDate.getTime() - 6 * 60 * 60 * 1000)
                        endDate.setTime(endDate.getTime() - 6 * 60 * 60 * 1000)
                    } else {
                        startDate.setTime(startDate.getTime() - 7 * 60 * 60 * 1000)
                        endDate.setTime(endDate.getTime() - 7 * 60 * 60 * 1000)
                    }

                    return {
                        id: d.id,
                        start: startDate.toISOString(),
                        end: endDate.toISOString(),
                        resource: d.user_id,
                        barColor: '#000000',
                        text: d.reason,
                        html: Tools.methods.formatIndispoEvent(d.reason),
                        clickDisabled: false, //brieuc true
                        resizeDisabled: true,
                        moveDisabled: true,
                    }
                })
                return formattedData
            } catch (err) {
                console.error(err)
            }
        },

        async createIndispo({ state }, data) {
            try {
                const res = await axios.post(`/indispo/${data.userId}`, data)
                return res.data
            } catch (err) {
                return err.response.data
            }
        },

        async updateIndispo(context, data) {
            try {
                const res = await axios.post(`/indispo/update/${data.indispoId}/${data.userId}`, data)
                return res.data
            } catch (err) {
                return err.response.data
            }
        },
        async deleteIndispo(context, indispoId) {
            try {
                const res = await axios.delete(`/indispo/${indispoId}`)
                return res.data
            } catch (err) {
                return err.response.data
            }
        },

        /////////////////
        // Affectation //
        /////////////////
        async getUserAffectationByEtabId(context, etab_id) {
            try {
                const res = await axios.post(`/indispo/update/${data.indispoId}/${data.userId}`, data)
                return res.data
            } catch (err) {
                return err.response.data
            }
        },
    },
})
